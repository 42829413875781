var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"kt_header",staticClass:"header flex-column header-fixed",attrs:{"id":"kt_header"}},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-none d-lg-flex align-items-center mr-3"},[_c('div',{staticClass:"mr-20"},[_c('router-link',{attrs:{"to":"/dashboard/overview"}},[_c('img',{staticClass:"logo",attrs:{"alt":"Logo","src":"/media/logos/logo.png"}})])],1),_c('ul',{staticClass:"header-tabs nav align-self-end font-size-lg",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link py-4 px-6 active",attrs:{"data-tab":"0","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"},on:{"click":_vm.setActiveTab}},[_vm._v(_vm._s(_vm.$t("MENU.DASHBOARD")))])]),_c('li',{staticClass:"nav-item mr-3"},[_c('a',{staticClass:"nav-link py-4 px-6",attrs:{"data-tab":"1","data-toggle":"tab","href":"#","role":"tab","aria-selected":"true"},on:{"click":_vm.setActiveTab}},[_vm._v(_vm._s(_vm.$t("MENU.Administration")))])])])]),_c('KTTopbar')],1)]),_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"container"},[_c('div',{ref:"kt_header_navs",staticClass:"header-navs header-navs-left",attrs:{"id":"kt_header_navs"}},[_c('b-tabs',{staticClass:"hide-tabs",model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',[_c('div',{staticClass:"tab-pane py-5 show active"},[_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[_c('KTMenu',{attrs:{"menuItems":[
                    ['Dokumente', 'dashboard/overview'] ].concat( (_vm.isAdmin === 1
                      ? [
                          ['Kunden', 'dashboard/clients'],
                          ['Artikelverwaltung', 'dashboard/items']
                        ]
                      : [])
                  )}})],1)])]),(_vm.isAdmin)?_c('b-tab',[_c('div',{staticClass:"tab-pane py-5 show active"},[_c('div',{ref:"kt_header_menu2",staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu2"}},[_c('KTMenu',{attrs:{"menuItems":[
                    ['Benutzerverwaltung', 'administration/usermanagement'],
                    ['E-Mail Konfiguration', 'administration/email'],
                    ['Textbausteine', 'administration/fixed-text'] ]}})],1)])]):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }